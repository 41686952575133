import { render, staticRenderFns } from "./brandsSection.vue?vue&type=template&id=7b600b2b&scoped=true&lang=html&"
import script from "./brandsSection.vue?vue&type=script&lang=js&"
export * from "./brandsSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b600b2b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KsImage: require('/var/www/app/components/base/ksImage.vue').default,SkewedCard: require('/var/www/app/components/base/skewedCard.vue').default})
