
import _orderBy from 'lodash/orderBy'
export default {
  props: {
    image: {
      type: Object,
      required: true
    },
    eager: {
      type: Boolean
    },
    contain: {
      type: Boolean
    },
    aspectRatio: {
      type: String | Number
    },
    height: {
      type: String | Number
    },
    width: {
      type: String | Number
    },
    maxWidth: {
      type: String | Number
    },
    maxHeight: {
      type: String | Number
    },
    fixed: {
      type: Boolean
    },
    size: {
      type: [Boolean, String, Number]
    }
  },
  data: () => ({
    webpSupport: true
  }),
  computed: {
    filteredImages () {
      const { webp } = this.$store.state.customModernizr
      const { formats } = JSON.parse(JSON.stringify(this.image))
      const sizesArray =  Object.keys(formats).reduce((acc, key, i) => {
        const item = formats[key]
        const img = item.find((it) => {
          return webp
            ? !['image/jpeg', 'image/jpg', 'image/png'].includes(it.mime)
            : it.mime !== 'image/webp'
        })
        if (img) {
          img.url = this.$config.strapiImgUrl + img.url
          acc.push(img)
        }
        return acc
      }, [])
      return _orderBy(sizesArray, 'width', 'desc')
    },
    computedSrcset () {
      return this.filteredImages.reduce((acc, img, i) => {
        return `${acc}${i > 0 ? ', ' : ''}${img.url} ${img.width}w`
      }, '')
    },
    // placeholder () {
    //   const base64Array = this.image.formats.base64
    //   if (!base64Array && !isArray(base64Array) || !base64Array.length) return
    //   return base64Array[0].url
    // }
  }
}
