
export default {
  props: {
    page: {
      type: Object
    }
  },
  async fetch () {
    this.brands = await this.$strapi.find('marques')
  },
  data: () => ({
    brands: [],
    model: null
  })
}
